import React from 'react';
import ParallaxContainer from "../components/ParallaxContainer";
import ImprintText from "../components/ImprintText";


export default class Imprint extends React.Component{

    componentDidMount() {
        window.scrollTo(0, 400);
    }

    render() {
        return(
            <div className="App">
                <ParallaxContainer image={require('../assets/Farben_Pinsel_Schablone_1600x1000_72ppi-min.jpg')}/>
                <ImprintText/>
                <ParallaxContainer image={require('../assets/Titelbild_Wohnhaus_1600x1065_72ppi-min.jpg')}/>
            </div>
        );
    }

}