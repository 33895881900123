import React, {Component} from 'react';
import Introduction from "../components/Introduction";
import ParallaxContainer from "../components/ParallaxContainer";
import Contact from "../components/Contact";
import Help from '../components/Help';



class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="App">
                <ParallaxContainer height={600} image={require('../assets/Farben_Pinsel_Schablone_1600x1000_72ppi-min.jpg')}/>
                <Introduction/>
                <ParallaxContainer height={600} image={require('../assets/Tapeten_Farbfaecher_Auswahl_1600x1000_72ppi-min.jpg')}/>
                <Help />
                <ParallaxContainer height={600} image={require('../assets/Bodenbelaege_Werkzeug_1600x1000_72ppi-min.jpg')}/>
                <Contact/>
                <ParallaxContainer height={900} image={require('../assets/Titelbild_Wohnhaus_1600x1065_72ppi-min.jpg')}/>
            </div>
        );
    }
}

export default Home;
