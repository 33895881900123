import React from 'react';


export default class Introduction extends React.Component{

    render() {
        return (
            <div className="container">
                <div className="section">
                    <div className="row">
                        <div className="col s12 center" id="leistungen">
                            <br/>
                            <div className="row">
                                <i class="fas fa-brush red-text text-darken-4"></i>
                                <br/>
                                <h5 className="red-text text-darken-4" >Unsere Leistungen</h5>
                                <br /><br />
                                <ul class="collection col m4 s12">
                                    <li class="collection-item">Maler- und Tapezierarbeit</li>
                                    <li class="collection-item">Vollwärmeschutz</li>
                                    <li class="collection-item">Türen- und Fensterrenovierung</li>
                                    <li class="collection-item">dekorative und kreative Gestaltungen und Schriften</li>
                                    <li class="collection-item">Brandschutzbeschichtung mit Zertifikat</li>

                                </ul>

                                <ul class="collection col m4 s12">
                                    <li class="collection-item">Möbelmalerei</li>
                                    <li class="collection-item">Vergoldung</li>
                                    <li class="collection-item">Industrieanstriche</li>
                                    <li class="collection-item">Insekten und Sonnenschutz</li>
                                    <li class="collection-item">Fassadengestaltung</li>
                                    <li class="collection-item">Strukturputze Außen und Innen</li>
                                    <li class="collection-item">Sanierputz</li>

                                </ul>

                                <ul class="collection col m4 s12">
                                    <li class="collection-item">Fußbodenlegearbeiten aller Art</li>
                                    <li class="collection-item">Fußbodenbeschichtung</li>
                                    <li class="collection-item">Parkett schleifen und versiegeln</li>
                                    <li class="collection-item">Laminat</li>
                                    <li class="collection-item">Holzschutz</li>
                                    
                                </ul>

                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }

}

