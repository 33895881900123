import React from 'react';
import {Link} from "react-router-dom";


export default class ImprintText extends React.Component {

    render() {
        return (
            <div className={'Privacy-text'}>
                <Link to="/" className={'red darken-4 btn'}>Zur Startseite</Link>

                <h1>Impressum</h1>
                <h2>Angaben gemäß § 5 TMG</h2>
                <p>
                   <strong>Malermeister Bernd Merkel</strong> <br/>
                    Hauptstraße 20 <br/>
                    09429 Wolkenstein OT Gehringswalde
                </p>
                <p>
                    <strong>Vertreten durch die Geschäftsführer:</strong> <br/>
                    Bernd Merkel <br/>
                </p>

                <h2>Kontakt</h2>
                <p>
                    Telefon: 0173 7777 832 <br/>
                    Festnetz: 037369 5949
                    E-Mail: info@malermeister-merkel.de <br/>
                </p>
                <h2>Berufskammer</h2>
                <p>
                    Handwerkskammer Chemnitz <br />
                    PF 415 <br />
                    09004 Chemnitz
                </p>
                <h2>Name des Registers</h2>
                <p>Handwerksrolle</p>
                <h2>Registernummer</h2>
                <p>0028544</p>
                <h2>Berufsbezeichnung</h2>
                <p>Maler und Lackierer, Bodenleger, Gerüstbauer</p>

                <h2>Streitschlichtung</h2>
                <p>
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                    <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.
                    Unsere E-Mail-Adresse finden Sie oben im Impressum. <br/>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <h2>Haftung für Inhalte</h2>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    Haftung für Links
                </p>
                <p>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                    haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                    verlinkten
                    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                    Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    <br/><br/>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                    einer
                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                    umgehend entfernen.
                </p>
                <h2>Urheberrecht</h2>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </p>
                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
                    eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    Quelle:
                    <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
                </p>

            </div>
        );
    }

}