import React from 'react';


export default class Help extends React.Component{

    render() {
        return (
            <div className="container">
                <div className="section">
                    <div className="row">
                        <div className="col s12 center" id="shop">
                            <br/>
                            <i class="fas fa-brush red-text text-darken-4"></i>
                            <br/>
                            <h5 className="red-text text-darken-4">Sie suchen eine individuelle Beratung?</h5>
                            <p>In unserem Büro beraten wir sie gern über ihr Vorhaben und helfen ihnen z.B dabei die richtigen Tapeten, Bodenbeläge und Farben zu finden.</p>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
