import React from 'react';
import ContactForm from "./ContactForm";


export default class Contact extends React.Component{

    render() {
        return(
            <div className="container" id="kontakt">
                <div className="section">
                    <div className="row">
                        <div className="col s12 center">
                            <br/>
                            <div className="row">
                                <i class="fas fa-info-circle red-text text-darken-4"></i>
                                <br/>
                                <h5 className="red-text text-darken-4">Sie haben Fragen ? </h5>
                                <div className="col s12 m5 l5">
                                    <br/><br/><br/>
                                    <div className="card ">
                                        <div className="card-content">
                                                <span
                                                    className="card-title red-text text-darken-4">Kontaktdaten</span>
                                            <br/><br/>
                                            <p>
                                                Malergeschäft<br />
                                                Haupstraße 20<br />
                                                09429 Wolkenstein<br />
                                                OT Gehringswalde
                                                <br/> <br />
                                                <strong
                                                    className="red-text text-darken-4">E-Mail-Adresse</strong>:
                                                info@malermeister-merkel.de <br/>
                                                <strong className="red-text text-darken-4">Telefon</strong> 037369 - 59 49 <br />
                                                <strong className="red-text text-darken-4">Mobil</strong> 0173 - 7777 832

                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col offset-l1 s12 m6 l6">
                                    <br/>
                                    <br/>
                                    <ContactForm />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

}