import React from 'react';
import {Link} from 'react-router-dom';

export default class Footer extends React.Component{

    render() {
        return(
            <footer className="page-footer red darken-4">
                <div className="footer-copyright">
                    <div className="container">
                        <Link to={'/impressum'} className="white-text">Impressum</Link> | <Link to={'/datenschutz'} className="white-text">Datenschutz</Link>
                    </div>
                </div>
            </footer>
        );
    }

}