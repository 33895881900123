import React from 'react';
import {Link} from 'react-router-dom';

export default class PrivacyText extends React.Component{

    render() {
        return (
            <div className={'Privacy-text'}>
                <Link to="/" className={'red darken-4 btn'}>Zur Startseite</Link>
                <h3 className={'red-text text-darken-4'}>Datenschutzerklärung</h3>

                <h3>Allgemeine Hinweise</h3>
                <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
                    Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten
                    sind
                    alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum
                    Thema
                    Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </p>
                <h3>Datenerfassung auf unserer Website</h3>
                <p><strong>Wer ist verantwortlich für die Datenerfassung auf
                    dieser Website?</strong>
                </p>
                <p>Die Datenverarbeitung auf dieser Website erfolgt durch den
                    Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                </p>
                <p><strong>Wie
                    erfassen wir Ihre Daten?</strong></p>
                <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                    mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                </p>
                <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                    allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                    Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                </p>
                <p><strong>Wofür
                    nutzen wir Ihre Daten?</strong></p>
                <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie
                    Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens
                    verwendet werden.
                </p>
                <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
                <p>Sie
                    haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                    gespeicherten
                    personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
                    Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                    sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
                    ein
                    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </p>
                <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
                <h3>Datenschutz</h3>
                <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
                    ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                    Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                </p>
                <p>Wenn Sie diese Website benutzen,
                    werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                    persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten
                    wir
                    erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                </p>
                <p>Wir
                    weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                </p>
                <h3>Hinweis zur verantwortlichen Stelle</h3>
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf
                    dieser Website ist:
                </p>
                <p>
                    Bernd Merkel <br/> <br/>
                </p>
                <p><strong>Kontakt</strong></p>
                <p>
                    Telefon: 0173 7777 832 <br/>
                    E-Mail: info@malermeister-merkel.de <br/>
                </p>
                <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
                    anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                    E-Mail-Adressen, o. ä.) entscheidet.
                </p>
                <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p>Viele Datenverarbeitungsvorgänge sind nur
                mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit
                widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
                Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
                <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                <p>Im Falle datenschutzrechtlicher
                Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
                Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes,
                in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
                Kontaktdaten können folgendem Link entnommen werden: <a
                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    target="_blank" rel="noreferrer noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            </p>
                <h3>Recht auf Datenübertragbarkeit</h3> <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten
                in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung
                der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
                ist.</p>
                <h3>SSL- bzw. TLS-Verschlüsselung</h3> <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
                Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol
                in Ihrer Browserzeile.</p> <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
                die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                <h3>Auskunft, Sperrung, Löschung</h3> <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
                Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
                oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie
                sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
                <h2>3. Datenerfassung auf unserer Website</h2>
                <h3>Kontaktformular</h3> <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
                Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
                Ihre Einwilligung weiter.</p> <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
                somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese
                Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
                Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
                unberührt.</p> <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns
                zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
                Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
                <h2>4. Plugins und Tools</h2>
                <h3>Google Web Fonts</h3> <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
                genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die
                benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p> <p>Zu
                diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen.
                Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
                Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung
                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                dar.</p> <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer
                genutzt.</p> <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                href="https://developers.google.com/fonts/faq"
                target="_blank" rel="noreferrer noopener">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von
                Google: <a href="https://www.google.com/policies/privacy/"
                           target="_blank" rel="noreferrer noopener">https://www.google.com/policies/privacy/</a>.</p>
                <h3>Google Maps</h3> <p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die
                Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p> <p>Zur Nutzung der Funktionen
                von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel
                an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat
                keinen Einfluss auf diese Datenübertragung.</p> <p>Die Nutzung von Google Maps erfolgt im Interesse
                einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns
                auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
                f DSGVO dar.</p> <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung
                von Google: <a href="https://www.google.de/intl/de/policies/privacy/"
                               target="_blank" rel="noreferrer noopener">https://www.google.de/intl/de/policies/privacy/</a>.</p>


            </div>
    );
    }

    }