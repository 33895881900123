import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import Home from "../pages/Home";
import Header from "./Header";
import Imprint from "../pages/Imprint";
import Privacy from "../pages/Privacy";
import Footer from "./Footer";

class AppRouter extends React.Component{


    render() {
        return(
            <Router>
                <div>
                    <Header/>
                        <Route path={'/'} exact component={Home}/>
                        <Route path={'/impressum'} exact component={Imprint}/>
                        <Route path={'/datenschutz'} exact component={Privacy}/>
                    <Footer/>

                </div>
            </Router>
        );
    }

}

export default AppRouter;