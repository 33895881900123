import React, {Component} from 'react';
import AppRouter from "./components/AppRouter";
import CookieConsent from "react-cookie-consent";


class App extends Component {
    render() {

        return (
            <div className="App">
                <AppRouter/>
                <CookieConsent
                    buttonStyle={{ background: "#AD2A42", color: 'white'}}
                >
                    Diese Website benutzt Cookies um die Funktionalität zu gewährleisten.
                </CookieConsent>
            </div>
        );
    }
}

export default App;
