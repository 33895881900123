import React from 'react';
import {Link} from 'react-router-dom';

export default class Header extends React.Component{

    render() {
        return(
            <div className="">
                <div className="center">
                    <br /><br />
                    <Link id="logo-container" to={'/'} className=" row">
                        <img src={require('../assets/bernd-logo.gif')} alt=""/>  
                    </Link>
                    <ul className="row nav">
                        <li><a href="#leistungen" title="Unsere Leistungen">Leistungen</a></li>
                        <li><a href="#shop" title="Lassen sie sich Beraten">Beratung</a></li>
                        <li><a href="#kontakt" title="Kontaktieren Sie uns">Kontakt</a></li>
                    </ul>
                </div>
            </div>
        );
    }

}