import React from 'react';
import Izitoast from 'izitoast';
import axios from 'axios';
import {Link} from 'react-router-dom';

const initalState = {firstname: '', lastname: '', email: '', phone: '', message: '', agb: false, emailError: '', messageError: ''};
const isIE = /*@cc_on!@*/false || !!document.documentMode;


export default class ContactForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = initalState;
        this.changeInput = this.changeInput.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    changeInput(e){
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]: value
        });
    }

    submitForm(e) {
        e.preventDefault();

        if(this.state.agb === false){
            Izitoast.error({
                message: "Bitte bestätigen Sie die AGB",
            });
            return;
        }

        axios.post('//mailer.malermeister-merkel.de', 'data=' + JSON.stringify(this.state), {headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(res => {
                console.log(res);

                if(res.data.errors.email || res.data.errors.message){

                    if(res.data.errors.email){
                        this.setState({emailError: res.data.errors.email});
                    }
                    if(res.data.errors.message){
                        this.setState({messageError: res.data.errors.message});
                    }

                    Izitoast.error({
                        message: res.data.message,
                    });

                }else{

                    this.setState(initalState);

                    Izitoast.success({
                        message: res.data.message,
                        position: 'topRight',
                        color: '#AD2A42',
                        messageColor: 'white'
                    });
                }

            })

    }

    render() {

        return (
            <div>
            {!isIE ?    
                <form action="#" method="post" id="" onSubmit={this.submitForm} className="">
         
                <div className="input-field ">
                    <input name="firstname" onChange={this.changeInput} value={this.state.firstname} type="text" id="firstname" />
                    <label htmlFor="firstname">Vorname</label>
                </div>

                <div className="input-field ">
                    <input name="lastname" onChange={this.changeInput} value={this.state.lastname}  type="text" id="lastname" />
                    <label htmlFor="lastname">Nachname</label>
                </div>

                <div className="input-field ">
                    <input name="email" onChange={this.changeInput} value={this.state.email}  type="email" id="email" />
                    <label htmlFor="email">E-Mail-Adresse</label>
                    <p className={'red-text'}>{this.state.emailError}</p>
                </div>

                <div className="input-field ">
                    <input name="phone" onChange={this.changeInput} value={this.state.phone}  type="text" id="phone" />
                    <label htmlFor="phone" className="">Telefonnummer
                        (optional)</label>
                </div>

                <div className="input-field ">
                    <textarea id="message" onChange={this.changeInput} className="materialize-textarea" value={this.state.message} name="message"></textarea>
                    <label htmlFor="message">Ihre Nachricht</label>
                    <p className={'red-text'}>{this.state.messageError}</p>

                </div>

                <p className="">
                    <input type="checkbox" className={'filled-in'} onChange={this.changeInput} checked={this.state.agb} name="agb" id='agb' />
                    <label htmlFor="agb">Ich stimme zu, dass meine Angaben aus
                        dem Kontaktformular zur Beantwortung
                        meiner Anfrage erhoben und verarbeitet werden. Die Daten
                        werden nach
                        abgeschlossener Bearbeitung Ihrer Anfrage gelöscht.
                        Hinweis: Sie können Ihre
                        Einwilligung jederzeit für die Zukunft per E-Mail an
                        info@malermeister-merkel.de widerrufen.
                        Detaillierte Informationen zum Umgang mit Nutzerdaten
                        finden Sie in unserer <Link to={'/datenschutz'}>Datenschutzerklärung</Link></label>
                </p>

                <div className="input-field ">
                    <input name="submit" className="btn red darken-4"
                           type="submit" id="submit" value="Absenden"/>
                    <label htmlFor="submit"></label>
                </div>
             </form>
            : 
            <p>Bei Internet-Explorer kann es zu Problemen mit dem Kontaktformular kommen. Bitte benutzen Sie einen anderen Browser</p>}
           
           </div>
        );
    }

}