import React from 'react';
import {Parallax} from 'react-parallax';


//  /*<div style={insideStyles}>{this.props.description}</div>*/

export default class ParallaxContainer extends React.Component{

    render() {
        return(
            <Parallax bgImage={this.props.image} strength={300}>
                <div style={{ height: this.props.height }}>
                </div>
            </Parallax>
        )
    }

}