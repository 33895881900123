import React from 'react';
import ParallaxContainer from "../components/ParallaxContainer";
import PrivacyText from "../components/PrivacyText";


export default class Privacy extends React.Component{

    componentDidMount() {
        window.scrollTo(0, 400);
    }

    render() {
        return(
            <div className="Privacy">
                <ParallaxContainer image={require('../assets/Farben_Pinsel_Schablone_1600x1000_72ppi-min.jpg')}/>
                <PrivacyText/>
                <ParallaxContainer image={require('../assets/Titelbild_Wohnhaus_1600x1065_72ppi-min.jpg')}/>
            </div>
        );
    }

}